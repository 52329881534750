import type { z } from "zod";
import { createInsertSchema, createSelectSchema } from "drizzle-zod";

import type { organisationPlanSchema } from "./constants";
import { organisation } from "./organisations";

export const selectOrganisationSchema = createSelectSchema(organisation);
export const insertOrganisationSchema = createInsertSchema(organisation);

export type InsertOrganisation = z.infer<typeof insertOrganisationSchema>;
export type Organisation = z.infer<typeof selectOrganisationSchema>;

export type OrganisationPlan = z.infer<typeof organisationPlanSchema>;
