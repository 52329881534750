import { jsonb, pgTable, text, timestamp, uuid } from "drizzle-orm/pg-core";

export const event = pgTable("event", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  name: text("name"),
  type: text("type"),
  values: jsonb("values"),
  createdAt: timestamp("createdAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
});
