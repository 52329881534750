import { createInsertSchema, createSelectSchema } from "drizzle-zod";
import { z } from "zod";

import { board, edge, node } from "./boards";
import { boardTypeSchema } from "./constants";

const nodePositionSchema = z.object({
  positionX: z.number(),
  positionY: z.number(),
});

export const selectBoardSchema = createSelectSchema(board, {
  type: boardTypeSchema.default("flow"),
});

export const insertBoardSchema = createInsertSchema(board, {
  name: z
    .string()
    .min(1, "Name must be at least 1 character long")
    .max(255, "Name must be at most 255 characters long"),
  type: boardTypeSchema.default("flow"),
}).omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  ownerId: true,
  organisationId: true,
});

const _nodeSchema = z.object({
  metricId: z.string().uuid().nullish(),
  betId: z.string().uuid(),
  jobId: z.string().uuid(),
  noteId: z.string().uuid(),
  type: z.union([
    z.literal("metric"),
    z.literal("bet"),
    z.literal("job"),
    z.literal("note"),
  ]),
});

const _nodeNodeMetricSchema = z.object({
  metricId: z.string().uuid(),
  type: z.literal("metric"),
});
const _nodeNodeBetSchema = z.object({
  betId: z.string().uuid(),
  type: z.literal("bet"),
});
const _nodeNodeJobSchema = z.object({
  jobId: z.string().uuid(),
  type: z.literal("job"),
});
const _nodeNodeNodeSchema = z.object({
  noteId: z.string().uuid(),
  type: z.literal("note"),
});

export const nodeUnionSchema = z.discriminatedUnion("type", [
  z
    .object({ metricId: z.string().uuid(), type: z.literal("metric") })
    .merge(nodePositionSchema),
  z
    .object({ betId: z.string().uuid(), type: z.literal("bet") })
    .merge(nodePositionSchema),
  z
    .object({ jobId: z.string().uuid(), type: z.literal("job") })
    .merge(nodePositionSchema),
  z
    .object({ noteId: z.string().uuid(), type: z.literal("note") })
    .merge(nodePositionSchema),
]);

export const insertNodeSchema = nodeUnionSchema;
export const selectNodeSchema = createSelectSchema(node, {});
export const patchNodeSchema = createInsertSchema(node, {
  positionX: z.number(),
  positionY: z.number(),
}).pick({ positionX: true, positionY: true });

export const updateNodeSchema = createInsertSchema(node, {
  positionX: z.number(),
  positionY: z.number(),
}).pick({ positionX: true, positionY: true });

export const selectEdgeSchema = createSelectSchema(edge, {
  score: z.string().or(z.number()).nullable(),
});
export const insertEdgeSchema = createInsertSchema(edge, {
  sourceId: z.string().uuid(),
  targetId: z.string().uuid(),
  sourceHandle: z.string(),
  targetHandle: z.string(),
  score: z.string(),
}).pick({
  sourceHandle: true,
  sourceId: true,
  targetHandle: true,
  targetId: true,
});

export const selectBoardWithNodeAndEdgeSchema = createSelectSchema(board, {
  type: boardTypeSchema.default("flow"),
}).extend({
  nodes: z.array(selectNodeSchema),
  edges: z.array(selectEdgeSchema),
});

export type InsertBoard = z.infer<typeof insertBoardSchema>;
export type Board = z.infer<typeof selectBoardSchema>;
export type InsertNode = z.infer<typeof insertNodeSchema>;
export type UpdateNode = z.infer<typeof updateNodeSchema>;
export type Node = z.infer<typeof selectNodeSchema>;
export type Edge = z.infer<typeof selectEdgeSchema>;
export type InsertEdge = z.infer<typeof insertEdgeSchema>;
export type BoardWithNodeAndEdge = z.infer<
  typeof selectBoardWithNodeAndEdgeSchema
>;
