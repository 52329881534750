import { createInsertSchema, createSelectSchema } from "drizzle-zod";
import { z } from "zod";

import type { credentialSourceSchema, oauthSourceSchema } from "./constants";
import { credentialRegionSchema } from "./constants";
import { credential, oauth } from "./credentials";

const credentialAmplitudeInput = z.object({
  sourceName: z.literal("amplitude"),
  apiKey: z.string().min(1),
  secretKey: z.string().min(1),
  region: credentialRegionSchema,
});

const credentialMixpanelInput = z.object({
  sourceName: z.literal("mixpanel"),
  username: z.string().min(1),
  password: z.string().min(1),
  projectId: z.string().min(1),
  region: credentialRegionSchema,
});

const credentialPostgresInput = z.object({
  sourceName: z.literal("postgres"),
  username: z.string().min(1),
  password: z.string().min(1),
  name: z.string().min(1),
  host: z.string().min(1),
  port: z.string().min(1),
});

const credentialBigQueryInput = z.object({
  sourceName: z.literal("bigQuery"),
  projectId: z.string().min(1),
  privateKey: z.string().min(1),
  clientEmail: z.string().min(1),
});

const credentialStripeInput = z.object({
  sourceName: z.literal("stripe"),
  apiKey: z.string().min(1),
});

const credentialRandomInput = z.object({
  sourceName: z.literal("random"),
  projectId: z.string().min(1),
});

const credentialInput = z.object({
  label: z.string(),
  displayName: z.string(),
});

export const selectCredentialSchema = createSelectSchema(credential, {}).pick({
  id: true,
  displayName: true,
  label: true,
  sourceName: true,
});

export const insertCredentialSchema = z.discriminatedUnion("sourceName", [
  credentialAmplitudeInput.merge(credentialInput),
  credentialMixpanelInput.merge(credentialInput),
  credentialPostgresInput.merge(credentialInput),
  credentialBigQueryInput.merge(credentialInput),
  credentialStripeInput.merge(credentialInput),
  credentialRandomInput.merge(credentialInput),
]);

export const selectOauthSchema = createSelectSchema(oauth, {});
export const selectSafeOauthSchema = createSelectSchema(oauth, {}).pick({
  id: true,
  displayName: true,
  sourceName: true,
  organisationId: true,
  createdAt: true,
  updatedAt: true,
});

export const insertOauthSchema = createInsertSchema(oauth).omit({
  id: true,
  organisationId: true,
  updatedAt: true,
  createdAt: true,
});

export type InsertCredential = z.infer<typeof insertCredentialSchema>;
export type Credential = z.infer<typeof selectCredentialSchema>;

export type InsertOauth = z.infer<typeof insertOauthSchema>;
export type Oauth = z.infer<typeof selectSafeOauthSchema>;

export type CredentialSource = z.infer<typeof credentialSourceSchema>;
export type OauthSource = z.infer<typeof oauthSourceSchema>;
