import { createInsertSchema, createSelectSchema } from "drizzle-zod";
import { z } from "zod";

import type { connectSourceSchema } from "./constants";
import {
  displayFormatSchema,
  periodSchema,
  positiveDirectionSchema,
  rollUpSchema,
  sourceCalculationSchema,
  sourceTypeSchema,
  strategyTypeSchema,
} from "./constants";
import {
  metric,
  metricEvent,
  metricInterval,
  metricKpi,
  metricSource,
} from "./metric";

export const insertMetricSchema = createInsertSchema(metric, {
  rollUp: rollUpSchema.default("sum"),
  strategyType: strategyTypeSchema.default("input"),
  positiveDirection: positiveDirectionSchema.default("up"),
  displayFormat: displayFormatSchema.default("number"),
}).omit({ organisationId: true });

export const selectMetricSchema = createSelectSchema(metric, {
  rollUp: rollUpSchema,
  strategyType: strategyTypeSchema,
  positiveDirection: positiveDirectionSchema,
  displayFormat: displayFormatSchema,
});

export const insertMetricEventSchema = createInsertSchema(metricEvent);
export const selectMetricEventSchema = createSelectSchema(metricEvent);

export const insertMetricIntervalSchema = createInsertSchema(metricInterval, {
  period: periodSchema,
});
export const selectMetricIntervalSchema = createSelectSchema(metricInterval, {
  period: periodSchema,
});

export const insertMetricKpiSchema = createInsertSchema(metricKpi);
export const selectMetricKpiSchema = createSelectSchema(metricKpi);

/* 

*/

const metricSourceInput = z.object({
  importTime: z.string(),
});

const metricSourceAmplitudeInput = z.object({
  sourceName: z.literal("amplitude"),
  type: sourceTypeSchema,
  credentialId: z.string(),
  eventName: z.string(),
});

const metricSourceMixpanelInput = z.object({
  sourceName: z.literal("mixpanel"),
  type: sourceTypeSchema,
  credentialId: z.string(),
  eventName: z.string(),
});

const metricSourcePostgresInput = z.object({
  sourceName: z.literal("postgres"),
  credentialId: z.string(),
  query: z.string(),
});

const metricSourceBigQueryInput = z.object({
  sourceName: z.literal("bigQuery"),
  credentialId: z.string(),
  query: z.string(),
});

const metricSourceStripeInput = z.object({
  sourceName: z.literal("stripe"),
  credentialId: z.string(),
});

const metricSourceRandomInput = z.object({
  sourceName: z.literal("random"),
  deviation: z.number().gt(0),
  percentage: z.number().gt(0),
});

const metricSourceCalculatedInput = z.object({
  sourceName: z.literal("calculated"),
  metricOne: z.string().uuid(),
  metricTwo: z.string().uuid(),
  calculation: sourceCalculationSchema,
});

export const insertMetricSourceSchema = z.discriminatedUnion("sourceName", [
  metricSourceAmplitudeInput.merge(metricSourceInput),
  metricSourceMixpanelInput.merge(metricSourceInput),
  metricSourcePostgresInput.merge(metricSourceInput),
  metricSourceBigQueryInput.merge(metricSourceInput),
  metricSourceStripeInput.merge(metricSourceInput),
  metricSourceRandomInput.merge(metricSourceInput),
  metricSourceCalculatedInput.merge(metricSourceInput),
]);
export const selectMetricSourceSchema = createSelectSchema(metricSource, {
  deviation: z.number(),
  percentage: z.number(),
});

export const selectMetricExpandSchema = createSelectSchema(metric, {
  rollUp: rollUpSchema,
  strategyType: strategyTypeSchema,
  positiveDirection: positiveDirectionSchema,
  displayFormat: displayFormatSchema,
}).extend({
  source: selectMetricSourceSchema.nullable(),
  intervals: z.array(selectMetricIntervalSchema),
  kpis: z.array(selectMetricKpiSchema),
});

export type InsertMetric = z.infer<typeof insertMetricSchema>;
export type Metric = z.infer<typeof selectMetricSchema>;
export type MetricExpanded = z.infer<typeof selectMetricExpandSchema>;
export type InsertMetricInterval = z.infer<typeof insertMetricIntervalSchema>;
export type MetricInterval = z.infer<typeof selectMetricIntervalSchema>;
export type InsertMetricKpi = z.infer<typeof insertMetricKpiSchema>;
export type MetricKpi = z.infer<typeof selectMetricKpiSchema>;
export type InsertMetricSource = z.infer<typeof insertMetricSourceSchema>;
export type MetricSource = z.infer<typeof selectMetricSourceSchema>;
export type InsertMetricEvent = z.infer<typeof insertMetricEventSchema>;
export type MetricEvent = z.infer<typeof selectMetricEventSchema>;

export type Rollup = z.infer<typeof rollUpSchema>;
export type SourceType = z.infer<typeof sourceTypeSchema>;
export type ConnectSource = z.infer<typeof connectSourceSchema>;
export type DisplayFormat = z.infer<typeof displayFormatSchema>;
export type PositiveDirection = z.infer<typeof positiveDirectionSchema>;
