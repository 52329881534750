import {
  integer,
  json,
  pgTable,
  text,
  timestamp,
  unique,
  uuid,
} from "drizzle-orm/pg-core";

import { organisation } from "../organisations";
import {
  credentialRegionEnum,
  credentialSourceEnum,
  oauthSourceEnum,
} from "./constants";

export const credential = pgTable("credential", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  sourceName: credentialSourceEnum("sourceName").notNull(),
  organisationId: uuid("organisationId")
    .notNull()
    .references(() => organisation.id),
  label: text("label").notNull(),
  displayName: text("displayName").notNull(),
  apiKey: text("apiKey"),
  region: credentialRegionEnum("region").default("us"),
  secretKey: text("secretKey"),
  username: text("username"),
  password: text("password"),
  projectId: text("projectId"),
  accountId: text("accountId"),
  workspaceId: text("workspaceId"),
  accessToken: text("accessToken"),
  refreshToken: text("refreshToken"),
  tokenType: text("tokenType"),
  name: text("name"),
  host: text("host"),
  port: text("port"),
  credentialJson: json("credentialJson"),
  privateKey: text("privateKey"),
  clientEmail: text("clientEmail"),
  expiresIn: integer("expiresIn"),
  createdAt: timestamp("createdAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp("updatedAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
});

export const oauth = pgTable(
  "oauth",
  {
    id: uuid("id").defaultRandom().primaryKey().notNull(),
    organisationId: uuid("organisationId")
      .notNull()
      .references(() => organisation.id),
    sourceName: oauthSourceEnum("sourceName").notNull(),
    displayName: text("displayName").notNull(),
    accessToken: text("accessToken").notNull(),
    refreshToken: text("refreshToken"),
    tokenType: text("tokenType").notNull(),
    expiresIn: integer("expiresIn").notNull(),
    createdAt: timestamp("createdAt", {
      precision: 6,
      withTimezone: true,
      mode: "string",
    })
      .defaultNow()
      .notNull(),
    updatedAt: timestamp("updatedAt", {
      precision: 6,
      withTimezone: true,
      mode: "string",
    })
      .defaultNow()
      .notNull(),
  },
  (t) => ({
    unique: unique("unique").on(t.organisationId, t.sourceName),
  }),
);
