import { relations } from "drizzle-orm";
import { pgTable, text, timestamp, uuid } from "drizzle-orm/pg-core";

import { organisation } from "../organisations";
import { user } from "../users";

export const note = pgTable("note", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  description: text("description").default("").notNull(),
  owner: uuid("owner").references(() => user.id),
  organisationId: uuid("organisationId")
    .notNull()
    .references(() => organisation.id),
  createdAt: timestamp("createdAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp("updatedAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
});

export const noteRelations = relations(note, ({ one }) => ({
  organisation: one(organisation, {
    fields: [note.organisationId],
    references: [organisation.id],
  }),
}));
