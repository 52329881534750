import { relations } from "drizzle-orm";
import {
  boolean,
  pgTable,
  primaryKey,
  text,
  timestamp,
  uuid,
} from "drizzle-orm/pg-core";

import { organisation } from "../organisations";
import { userRoleEnum } from "./constants";

export const user = pgTable("user", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  name: text("name").notNull(),
  avatar: text("avatar").notNull(),
  email: text("email").notNull(),
  tenantId: uuid("tenantId").notNull(),
  onboarded: boolean("onboarded").default(false).notNull(),
  createdAt: timestamp("createdAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp("updatedAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
});

export const usersRelations = relations(user, ({ many }) => ({
  usersToOrganisations: many(usersToOrganisations),
}));

export const usersToOrganisations = pgTable(
  "organisation_member",
  {
    userId: uuid("userId")
      .notNull()
      .references(() => user.id),
    organisationId: uuid("organisationId")
      .notNull()
      .references(() => organisation.id),
    role: userRoleEnum("role").notNull().default("member"),
    assignedAt: timestamp("assignedAt", { precision: 3, mode: "string" })
      .defaultNow()
      .notNull(),
  },
  (table) => {
    return {
      organisationMembersPkey: primaryKey({
        columns: [table.userId, table.organisationId],
        name: "organisation_members_pkey",
      }),
    };
  },
);

export const usersToOrganisationRelations = relations(
  usersToOrganisations,
  ({ one }) => ({
    organisation: one(organisation, {
      fields: [usersToOrganisations.organisationId],
      references: [organisation.id],
    }),

    user: one(user, {
      fields: [usersToOrganisations.userId],
      references: [user.id],
    }),
  }),
);

export const userWaitingList = pgTable("user_waiting_list", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  added: boolean("added").default(false),
  approved: boolean("approved").default(false),
  email: text("email").unique().notNull(),
  createdAt: timestamp("createdAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp("updatedAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
});
