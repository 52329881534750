import { relations } from "drizzle-orm";
import {
  boolean,
  date,
  decimal,
  integer,
  pgTable,
  primaryKey,
  text,
  timestamp,
  uuid,
} from "drizzle-orm/pg-core";

import { node } from "../boards";
import { credential } from "../credentials/credentials";
import { organisation } from "../organisations";
import { user } from "../users";
import {
  connectSourceEnum,
  displayFormatEnum,
  periodEnum,
  positiveDirectionEnum,
  rollUpEnum,
  sourceCalculationEnum,
  sourceRegionEnum,
  sourceTypeEnum,
  strategyTypeEnum,
} from "./constants";

export const metric = pgTable("metric", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  name: text("name").notNull(),
  description: text("description").default("").notNull(),
  technicalDescription: text("technicalDescription").default("").notNull(),
  displayFormat: displayFormatEnum("displayFormat").default("number").notNull(), // number | percent | dollar | pound  | euro | hours | minutes | seconds
  rollUp: rollUpEnum("rollUp").default("sum").notNull(), // sum | average | increase | weighted_average | last_month_available
  positiveDirection: positiveDirectionEnum("positiveDirection")
    .default("up")
    .notNull(), // up | down
  strategyType: strategyTypeEnum("strategyType").default("input").notNull(), // north_star | kpi | input
  owner: uuid("owner").references(() => user.id),
  organisationId: uuid("organisationId")
    .notNull()
    .references(() => organisation.id),
  sourceId: uuid("sourceId").references(() => metricSource.id, {
    onDelete: "cascade",
  }),
  createdAt: timestamp("createdAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp("updatedAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
});

export const metricRelations = relations(metric, ({ one, many }) => ({
  organisation: one(organisation, {
    fields: [metric.organisationId],
    references: [organisation.id],
  }),
  source: one(metricSource, {
    fields: [metric.sourceId],
    references: [metricSource.id],
  }),
  events: many(metricEvent),
  intervals: many(metricInterval),
  kpis: many(metricKpi),
  nodes: many(node),
}));

export const metricSource = pgTable("metric_source", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  credentialId: uuid("credentialId").references(() => credential.id),
  sourceName: connectSourceEnum("sourceName").notNull(),
  scheduled: boolean("scheduled").default(true).notNull(),
  lastRunAt: timestamp("date", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  }),
  importTime: text("importTime").default("00:00").notNull(),
  // mixpanel and amplitude
  eventName: text("eventName"),
  region: sourceRegionEnum("region"),
  type: sourceTypeEnum("type"),
  // postgres and bigquery
  query: text("query"),
  // random data
  deviation: decimal("deviation", {
    precision: 6,
    scale: 2,
  }).$type<number>(),
  percentage: integer("percentage"),
  // For calulcated
  metricOne: uuid("metricOne"),
  metricTwo: uuid("metricTwo"),
  calculation: sourceCalculationEnum("calculation"),
});

export const metricSourceRelations = relations(metricSource, ({ one }) => ({
  credential: one(credential, {
    fields: [metricSource.credentialId],
    references: [credential.id],
  }),
}));

export const metricInterval = pgTable("metric_interval", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  metricId: uuid("metricId")
    .notNull()
    .references(() => metric.id, {
      onDelete: "cascade",
    }),
  change: integer("change"),
  total: integer("total"),
  length: integer("length").notNull(),
  period: periodEnum("period").notNull(),
  days: integer("days").default(0).notNull(),
});

export const metricIntervalRelations = relations(metricInterval, ({ one }) => ({
  metric: one(metric, {
    fields: [metricInterval.metricId],
    references: [metric.id],
  }),
}));

export const metricKpi = pgTable("metric_kpi", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  metricId: uuid("metricId")
    .notNull()
    .references(() => metric.id, {
      onDelete: "cascade",
    }),
  startDate: date("startDate", {
    mode: "string",
  }).notNull(),
  endDate: date("endDate", {
    mode: "string",
  }).notNull(),
  startValue: integer("startValue").default(0).notNull(),
  targetValue: integer("targetValue").default(0).notNull(),
  currentValue: integer("currentValue"),
});

export const metricKpiRelations = relations(metricKpi, ({ one }) => ({
  metric: one(metric, {
    fields: [metricKpi.metricId],
    references: [metric.id],
  }),
}));

export const metricEvent = pgTable(
  "metric_event",
  {
    metricId: uuid("metricId")
      .notNull()
      .references(() => metric.id, {
        onDelete: "cascade",
      }),
    date: date("date", {
      mode: "string",
    }).notNull(),
    value: integer("value").notNull(),
  },
  (table) => {
    return {
      metricDatePkey: primaryKey({
        columns: [table.metricId, table.date],
        name: "metric_date_pkey",
      }),
    };
  },
);

export const metricEventRelations = relations(metricEvent, ({ one }) => ({
  metric: one(metric, {
    fields: [metricEvent.metricId],
    references: [metric.id],
  }),
}));
