import type { z } from "zod";
import { createInsertSchema, createSelectSchema } from "drizzle-zod";

import { note } from "./note";

export const insertNoteSchema = createInsertSchema(note).omit({
  organisationId: true,
});
export const selectNoteSchema = createSelectSchema(note);

export type InsertNote = z.infer<typeof insertNoteSchema>;
export type Note = z.infer<typeof selectNoteSchema>;
