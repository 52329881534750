import { relations } from "drizzle-orm";
import { pgTable, text, timestamp, unique, uuid } from "drizzle-orm/pg-core";

import { organisation } from "../organisations";
import { userRoleEnum } from "../users/constants";

export const invitation = pgTable(
  "invitation",
  {
    id: uuid("id").defaultRandom().primaryKey().notNull(),
    email: text("email").notNull(),
    role: userRoleEnum("type").notNull().default("member"),
    organisationId: uuid("organisationId")
      .notNull()
      .references(() => organisation.id),
    token: text("token").notNull(),
    createdAt: timestamp("createdAt", {
      precision: 6,
      withTimezone: true,
      mode: "string",
    })
      .defaultNow()
      .notNull(),
    expiresAt: timestamp("expiresAt", {
      precision: 6,
      withTimezone: true,
      mode: "string",
    }).notNull(),
    acceptedAt: timestamp("acceptedAt", {
      precision: 6,
      withTimezone: true,
      mode: "string",
    }),
  },
  (t) => ({
    unique: unique("emailOrg").on(t.organisationId, t.email),
  }),
);

export const invitationRelations = relations(invitation, ({ one }) => ({
  organisation: one(organisation, {
    fields: [invitation.organisationId],
    references: [organisation.id],
  }),
}));
