import { relations } from "drizzle-orm";
import { pgTable, text, timestamp, uuid } from "drizzle-orm/pg-core";

import { organisation } from "../organisations";
import { user } from "../users";

export const bet = pgTable("bet", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  name: text("name").notNull(),
  description: text("description").default("").notNull(),
  goal: text("goal").default("").notNull(),
  result: text("result").default("").notNull(),
  confidence: text("confidence"),
  status: text("status").default("idea").notNull(),
  dueDate: timestamp("dueDate", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  }),
  owner: uuid("owner").references(() => user.id),
  organisationId: uuid("organisationId")
    .notNull()
    .references(() => organisation.id),
  createdAt: timestamp("createdAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp("updatedAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
});

export const betRelation = relations(bet, ({ one }) => ({
  organisation: one(organisation, {
    fields: [bet.organisationId],
    references: [organisation.id],
  }),
}));
