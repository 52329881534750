import { pgEnum } from "drizzle-orm/pg-core";
import { z } from "zod";

export const oauthSource = [
  "slack",
  "linear",
  "asana",
  "productboard",
  "jira",
] as const;
export const oauthSourceEnum = pgEnum("OauthSourceEnum", oauthSource);
export const oauthSourceSchema = z.enum(oauthSource);

const credentialRegion = ["us", "eu"] as const;
export const credentialRegionEnum = pgEnum(
  "CredentialRegion",
  credentialRegion,
);
export const credentialRegionSchema = z.enum(credentialRegion);

const credentialSource = [
  "amplitude",
  "mixpanel",
  "random",
  "postgres",
  "bigQuery",
  "stripe",
  "calculated",
] as const;
export const credentialSourceEnum = pgEnum(
  "CredentialSource",
  credentialSource,
);
export const credentialSourceSchema = z.enum(credentialSource);
