import { pgEnum } from "drizzle-orm/pg-core";
import { z } from "zod";

export const boardType = ["flow", "composition"] as const;
export const boardTypeEnum = pgEnum("BoardType", boardType);
export const boardTypeSchema = z.enum(boardType);

export const boardNodeType = ["metric", "bet", "job", "note"] as const;
export const boardNodeTypeEnum = pgEnum("BoardNodeType", boardNodeType);
export const boardNodeTypeSchema = z.enum(boardNodeType);
