import type { z } from "zod";
import { createInsertSchema, createSelectSchema } from "drizzle-zod";

import { job, jobSource } from "./job";

export const selectJobSchema = createSelectSchema(job);
export const insertJobSchema = createInsertSchema(job).omit({
  credentialId: true,
  organisationId: true,
});

export const selectJobSourceSchema = createSelectSchema(jobSource);
export const insertJobSourceSchema = createInsertSchema(jobSource);

export type InsertJob = z.infer<typeof insertJobSchema>;
export type Job = z.infer<typeof selectJobSchema>;
export type JobWithSource = z.infer<typeof selectJobSchema> & {
  source: z.infer<typeof selectJobSourceSchema> | null;
};

export type InsertJobSource = z.infer<typeof insertJobSourceSchema>;
export type JobSource = z.infer<typeof selectJobSourceSchema>;
