import type { z } from "zod";
import { createInsertSchema, createSelectSchema } from "drizzle-zod";

import { bet } from "./bets";

export const selectBetSchema = createSelectSchema(bet);
export const insertBetSchema = createInsertSchema(bet).omit({
  id: true,
  updatedAt: true,
  createdAt: true,
  organisationId: true,
  owner: true,
});

export type InsertBet = z.infer<typeof insertBetSchema>;
export type Bet = z.infer<typeof selectBetSchema>;
