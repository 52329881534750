import { relations } from "drizzle-orm";
import {
  decimal,
  integer,
  pgTable,
  text,
  timestamp,
  uuid,
} from "drizzle-orm/pg-core";

import { bet } from "../bets";
import { job } from "../jobs";
import { metric } from "../metrics";
import { note } from "../notes";
import { organisation } from "../organisations";
import { user } from "../users";
import { boardNodeTypeEnum, boardTypeEnum } from "./constants";

export const board = pgTable("board", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  name: text("name").notNull(),
  type: boardTypeEnum("type").notNull().default("flow"),
  slackChannel: text("slackChannel"),
  organisationId: uuid("organisationId")
    .notNull()
    .references(() => organisation.id),
  ownerId: uuid("owner").references(() => user.id),
  createdAt: timestamp("createdAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp("updatedAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
});

export const boardRelation = relations(board, ({ one, many }) => ({
  organisation: one(organisation, {
    fields: [board.organisationId],
    references: [organisation.id],
  }),
  owner: one(user, {
    fields: [board.ownerId],
    references: [user.id],
  }),
  nodes: many(node),
  edges: many(edge),
}));

export const node = pgTable("node", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  boardId: uuid("boardId")
    .notNull()
    .references(() => board.id, {
      onDelete: "cascade",
    }),
  positionX: integer("positionX").notNull(),
  positionY: integer("positionY").notNull(),
  type: boardNodeTypeEnum("type").notNull(),
  metricId: uuid("metricId").references(() => metric.id, {
    onDelete: "cascade",
  }),
  betId: uuid("betId").references(() => bet.id),
  jobId: uuid("jobId").references(() => job.id),
  noteId: uuid("noteId").references(() => note.id, {
    onDelete: "cascade",
  }),
});

export const nodeRelation = relations(node, ({ one }) => ({
  board: one(board, {
    fields: [node.boardId],
    references: [board.id],
  }),
  metric: one(metric, {
    fields: [node.metricId],
    references: [metric.id],
  }),
  bet: one(bet, {
    fields: [node.betId],
    references: [bet.id],
  }),
  job: one(job, {
    fields: [node.jobId],
    references: [job.id],
  }),
  note: one(note, {
    fields: [node.noteId],
    references: [note.id],
  }),
}));

export const edge = pgTable("edge", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  boardId: uuid("boardId")
    .notNull()
    .references(() => board.id, { onDelete: "cascade" }),
  sourceId: uuid("sourceId")
    .notNull()
    .references(() => node.id, { onDelete: "cascade" }),
  targetId: uuid("targetId")
    .references(() => node.id, {
      onDelete: "cascade",
    })
    .notNull(),
  sourceHandle: text("sourceHandle").notNull(),
  targetHandle: text("targetHandle").notNull(),
  score: decimal("score"),
  type: text("type"),
});

export const edgeRelation = relations(edge, ({ one }) => ({
  project: one(board, {
    fields: [edge.boardId],
    references: [board.id],
  }),
}));
