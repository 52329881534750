import { pgEnum } from "drizzle-orm/pg-core";
import { z } from "zod";

export const displayFormat = [
  "number",
  "percent",
  "dollar",
  "pound",
  "euro",
  "hours",
  "minutes",
  "seconds",
] as const;
export const displayFormatEnum = pgEnum("DisplayFormat", displayFormat);
export const displayFormatSchema = z.enum(displayFormat);

export const rollUp = ["sum", "average", "increase"] as const;
export const rollUpEnum = pgEnum("RollUp", rollUp);
export const rollUpSchema = z.enum(rollUp);

export const positiveDirection = ["up", "down"] as const;
export const positiveDirectionEnum = pgEnum(
  "PositiveDirection",
  positiveDirection,
);
export const positiveDirectionSchema = z.enum(positiveDirection);

export const strategyType = ["north_star", "kpi", "input"] as const;
export const strategyTypeEnum = pgEnum("StrategyType", strategyType);
export const strategyTypeSchema = z.enum(strategyType);

export const importance = ["minor", "medium", "major"] as const;
export const importanceEnum = pgEnum("Importance", importance);
export const importanceSchema = z.enum(importance);

export const impact = ["none", "positive", "negative"] as const;
export const impactEnum = pgEnum("Impact", impact);
export const impactSchema = z.enum(impact);

export const connectSource = [
  "amplitude",
  "mixpanel",
  "random",
  "postgres",
  "bigQuery",
  "stripe",
  "calculated",
] as const;
export const connectSourceEnum = pgEnum("ConnectSource", connectSource);
export const connectSourceSchema = z.enum(connectSource);

export const sourceRegion = ["eu", "us", "se"] as const;
export const sourceRegionEnum = pgEnum("SourceRegion", sourceRegion);
export const sourceRegionSchema = z.enum(sourceRegion);

export const sourceType = ["events", "charts"] as const;
export const sourceTypeEnum = pgEnum("SourceType", sourceType);
export const sourceTypeSchema = z.enum(sourceType);

export const sourceCalculation = [
  "addition",
  "subtraction",
  "mutliplication",
  "division",
] as const;
export const sourceCalculationEnum = pgEnum(
  "SourceCalculation",
  sourceCalculation,
);
export const sourceCalculationSchema = z.enum(sourceCalculation);

export const period = ["days", "weeks", "months", "years"] as const;
export const periodEnum = pgEnum("PeriodEnum", period);
export const periodSchema = z.enum(period);
