import { relations } from "drizzle-orm";
import { integer, pgTable, text, timestamp, uuid } from "drizzle-orm/pg-core";

import { oauthSourceEnum } from "../credentials/constants";
import { oauth } from "../credentials/credentials";
import { organisation } from "../organisations";
import { jobTypeEnum } from "./constants";

export const job = pgTable("job", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  organisationId: uuid("organisationId")
    .references(() => organisation.id)
    .notNull(),
  sourceId: uuid("sourceId").references(() => jobSource.id),
  typeId: text("typeId").notNull(),
  type: oauthSourceEnum("type").notNull(),
  credentialId: uuid("credentialId")
    .notNull()
    .references(() => oauth.id),
  createdAt: timestamp("createdAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp("updatedAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
});

export const jobRelations = relations(job, ({ one }) => ({
  organisation: one(organisation, {
    fields: [job.organisationId],
    references: [organisation.id],
  }),
  source: one(jobSource, {
    fields: [job.sourceId],
    references: [jobSource.id],
  }),
}));

export const jobSource = pgTable("job_source", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  name: text("name").notNull(),
  description: text("description").default("").notNull(),
  url: text("url").notNull(),
  type: jobTypeEnum("type").default("epic").notNull(),
  issues: integer("issues"),
  completed: integer("completed"),
  status: text("status"),
  lead: text("lead"),
  sourceId: text("sourceId").notNull(),
});
