import { createInsertSchema, createSelectSchema } from "drizzle-zod";
import { z } from "zod";

import type { userRoleSchema } from "./constants";
import { user, userWaitingList } from "./users";

export const selectUserSchema = createSelectSchema(user);
export const insertUserSchema = createInsertSchema(user).omit({
  id: true,
  updatedAt: true,
  createdAt: true,
});
export const selectUserWaitingListSchema = createSelectSchema(userWaitingList);
export const insertUserWaitingListSchema = createInsertSchema(userWaitingList, {
  email: z.string().email(),
});

export type InsertUser = z.infer<typeof insertUserSchema>;
export type User = z.infer<typeof selectUserSchema>;
export type InsertUserWaitingList = z.infer<typeof insertUserWaitingListSchema>;
export type UserWaitingList = z.infer<typeof selectUserWaitingListSchema>;

export type UserRole = z.infer<typeof userRoleSchema>;
