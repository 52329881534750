import { relations } from "drizzle-orm";
import { boolean, pgTable, text, timestamp, uuid } from "drizzle-orm/pg-core";

import { board } from "../boards";
import { usersToOrganisations } from "../users";
import { organisationPlanEnum } from "./constants";

export const organisation = pgTable("organisation", {
  id: uuid("id").defaultRandom().primaryKey().notNull(),
  name: text("name").notNull(),
  domain: text("domain"),
  discoverable: boolean("discoverable").default(false),
  stripeId: text("stripe_id").unique(),
  subscriptionId: text("subscription_id"),
  plan: organisationPlanEnum("plan").notNull().default("free"),
  endsAt: timestamp("endsAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  }),
  paidUntil: timestamp("paidUntil", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  }),
  createdAt: timestamp("createdAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp("updatedAt", {
    precision: 6,
    withTimezone: true,
    mode: "string",
  })
    .defaultNow()
    .notNull(),
});

export const organisationRelations = relations(organisation, ({ many }) => ({
  usersToOrganisations: many(usersToOrganisations),
  boards: many(board),
}));
