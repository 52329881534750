import { createInsertSchema, createSelectSchema } from "drizzle-zod";
import { z } from "zod";

import { userRoleSchema } from "../users";
import { invitation } from "./invitation";

export const insertInvitationSchema = createInsertSchema(invitation, {
  email: z.string().email(),
  role: userRoleSchema,
}).pick({
  email: true,
  role: true,
  token: true,
});

export const selectInvitationSchema = createSelectSchema(invitation);

export type InsertInvitation = z.infer<typeof insertInvitationSchema>;
export type Invitation = z.infer<typeof selectInvitationSchema>;
